<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div v-if="!loading" class="column is-5 is-offset-4">
          <div v-if="notFound" class="box">
            Halaman tidak ditemukan.
          </div>
          <form v-else>
            <div class="box">
              <b-field class="field-spacer"></b-field>
              <template v-if="errorMap.non_field_errors">
                <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
                <b-field class="field-spacer"></b-field>
              </template>
              <b-field
                :type="{'is-danger': errorMap.new_password1}"
                :message="errorMap.new_password1"
              >
                <b-input
                  v-model="user.new_password1"
                  type="password"
                  size="is-large"
                  placeholder="Kata Sandi Baru"
                  iconPack="fas"
                  icon="lock"
                  password-reveal
                  @input="validateInput('new_password1')"
                ></b-input>
              </b-field>
              <b-field
                :type="{'is-danger': errorMap.new_password2}"
                :message="errorMap.new_password2"
              >
                <b-input
                  v-model="user.new_password2"
                  type="password"
                  size="is-large"
                  placeholder="Konfirmasi Kata Sandi Baru"
                  iconPack="fas"
                  icon="lock"
                  password-reveal
                  @input="validateInput('new_password2')"
                ></b-input>
              </b-field>
              <b-button
                type="is-primary"
                size="is-large"
                expanded
                :disabled="!isValid"
                @click.stop.prevent="saveObject"
              >Reset</b-button>
            </div>
          </form>
        </div>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import User from "../models/resetPass.js";

export default {
  name: "ResetPassword",
  mixins: [createUpdateMixin],
  data() {
    this.userMdl = new User();
    this.objectMdl = this.userMdl;
    let obv = this.userMdl.getObservables();
    return obv;
  },
  methods: {
    saveObject() {
      if (this.user.new_password1 != this.user.new_password2) {
        this.validity.validated = false;
        this.validity.edited = false;
        this.errorMap.non_field_errors = "Kedua sandi tidak sama.";
      } else {
        this.user.id = this.$route.params.id;
        this.objectMdl.save(this.saveContext, this.onSaved);
      }
    },
    onSaved() {
      this.$router.replace({
        name: "login"
      });
    },
  },
  watch: {
    user: {
      // password berubah, update edited
      handler() {
        if (!this.userMdl.getEdited()) {
          this.setEdited(true);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  font-size: 14px;
  font-weight: 300;
}

button {
  margin: 0.25rem;
}

::v-deep .is-right {
  color: $ppds-blue !important;
}
</style>
