import axios from "axios";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "user";
const modelPath = "/anggota/reset-password/";

class User extends ModelBase {
  constructor() {
    let model = {
      id: null,
      new_password1: "",
      new_password2: "",
    };
    let requiredFields = ["new_password1", "new_password2"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.notFound = false;
  }

  load(id) {
    let loadURL = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.get(loadURL)
      .catch(error => {
        this.observables.notFound = true;
        return Promise.reject(error);
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }
}

export default User;